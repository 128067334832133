import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Title from '../components/Title';
import '../pages/company/style.sass';

export const CompanyPageTemplate = ({
  contentComponent,
  title,
  company_name,
  company_representative,
  address,
  date_establishment,
  email,
}) => {
  // const PageContent = contentComponent || Content;

  return (
    <div className="company">
      <Title>{title}</Title>
      <div className="company__inner">
        <div className="company__table">
          <div className="company__tableCell company__tableCell--area1">
            会社名
          </div>
          <div className="company__tableCell company__tableCell--area2">
            {company_name}
          </div>
          <div className="company__tableCell company__tableCell--area3">
            代表者
          </div>
          <div className="company__tableCell company__tableCell--area4">
            {company_representative}
          </div>
          <div className="company__tableCell company__tableCell--area5">
            本社
          </div>
          <div className="company__tableCell company__tableCell--area6">
            {address}
          </div>
          <div className="company__tableCell company__tableCell--area7">
            設立
          </div>
          <div className="company__tableCell company__tableCell--area8">
            {date_establishment}
          </div>
          <div className="company__tableCell company__tableCell--area9">
            お問い合わせ
          </div>
          <div className="company__tableCell company__tableCell--area10">
            <a href="mailto:{email}">{email}</a>
          </div>
          <div className="company__tableCell company__tableCell--area11">
            <iframe
              title="googleMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3242.024679625913!2d139.70881421534443!3d35.65176358020115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188b42707cfad1%3A0x726852e0cc3d2358!2z44CSMTUwLTAwMTEg5p2x5Lqs6YO95riL6LC35Yy65p2x77yT5LiB55uu77yW4oiS77yS77ySIOOCueODmuODvOOCt-OCouaBteavlOWvvw!5e0!3m2!1sja!2sjp!4v1584522666574!5m2!1sja!2sjp"
              className="company__map"
              width="100%"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              frameBorder="no"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

CompanyPageTemplate.propTypes = {
  title: PropTypes.string,
  company_name: PropTypes.string,
  company_representative: PropTypes.string,
  address: PropTypes.string,
  date_establishment: PropTypes.string,
  email: PropTypes.string,
};

const CompanyPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Seo
        title="会社概要 | ヒナタビ"
        description="神社／パワースポット検索サイト・ヒナタビの会社概要です"
        href={location.href}
      />
      <CompanyPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        company_name={post.frontmatter.company_name}
        company_representative={post.frontmatter.company_representative}
        address={post.frontmatter.address}
        date_establishment={post.frontmatter.date_establishment}
        email={post.frontmatter.email}
      />
    </Layout>
  );
};

CompanyPage.propTypes = {
  title: PropTypes.string,
  company_name: PropTypes.string,
  company_representative: PropTypes.string,
  address: PropTypes.string,
  date_establishment: PropTypes.string,
  email: PropTypes.string,
};

export default CompanyPage;

export const pageQuery = graphql`
  query companyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "company-page" } }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        company_name
        company_representative
        address
        date_establishment
        email
      }
    }
  }
`;
